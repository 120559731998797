import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/analiza-przedwdrozeniowa.jpg";
import Lightbox from "../components/lightBox";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


const Analiza = ({ data }) => {
  return (
    <Artykul
      title="Czym jest analiza przedwdrożeniowa? Dlaczego to must-have wdrożenia systemu dedykowanego?"
      articleImage={obrazekArtykulu}
      articleImageAlt="Czym jest analiza przedwdrożeniowa? Dlaczego to must-have wdrożenia systemu dedykowanego?"
      metaTitle="Analiza przedwdrożeniowa – jak robić to skutecznie?"
      metaDescription="Analiza przedwdrożeniowa w Twojej firmie ✔️ Korzyści • Implementacja procesu • Niezbędne elementy • Współpraca • Problemy podczas wdrażania • Video"
    >
      
      <p style={{
          paddingTop: 30,
        }}>Chcesz wdrożyć system i nie wiesz czym jest zaproponowana przez dostawcę analiza przedwdrożeniowa? Jeśli tak, to zapraszamy do zapoznania się z naszym materiałem dzięki, któremu dowiesz się jak analiza biznesowa uchroni Cię przed błędami i ułatwi implementacje całego procesu.
</p>


<p>
W kolejnym odcinku z serii wywiadów poświęconych rozmowom z praktykami biznesu o&nbsp;sprzedaży, obsłudze klienta i szeroko pojętej technologii, omówiliśmy ważny proces, bez którego wdrożenie nowego systemu może być mniej efektywne. Mowa o analizie przedwdrożeniowej. W niniejszym materiale skupiliśmy się na najistotniejszych elementach, wyzwaniach oraz roli jaką odgrywa analiza we wdrażaniu rozwiązań informatycznych. 
</p>

<p>
Wywiad przeprowadziła Joanna Cieluch, a jej gościem był Wojciech Nosal - Business Unit Manager w Questy, który posiada wieloletnie doświadczenie w przeprowadzaniu analiz przedwdrożeniowych. 
</p>

<p>
<strong>Jeśli chcesz poznać niezbędne elementy analizy biznesowej oraz dowiedzieć się więcej o płynących z&nbsp;niej korzyściach – ten odcinek jest właśnie dla Ciebie!</strong>
</p>
     <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="xqZlfUHuoG4"
          title="Czym jest analiza przedwdrożeniowa?"
        />
      </div>

      <p style={{
          paddingTop: 20,
        }}><strong> 
        Na wstępie warto zadać pytanie czym właściwie jest analiza przedwdrożeniowa?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
       Analiza przedwdrożeniowa to tak naprawdę proces, w którym wspólnie z klientem budujemy wizję rozwiązania. Dodatkowo uszczegóławiamy zakres jego oczekiwań oraz tworzymy dokumentację, która pozwala na sprawne wdrożenie systemu. Mówiąc prościej – wspólnie z&nbsp;klientem projektujemy jego rozwiązanie informatyczne. Dzięki temu jest on w pełni świadom, co od nas otrzyma, a my wiemy, co&nbsp;mamy dla niego wykonać.

</p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
       Zważając na poprzednią odpowiedź powstaje pytanie, czy analiza jest stałym elementem wdrożenia?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      Nie zawsze. Chociaż z naszej perspektywy, w&nbsp;momencie, w którym mamy do czynienia z&nbsp;większym projektem wymagającym  dostosowania systemu do specyfiki firmy lub danej branży –&nbsp;wykonanie takiej analizy przedwdrożeniowej jest wskazane. Jeżeli natomiast mówimy o&nbsp;implementacji prostszego rozwiązania to wystarczy nam podstawowa konfiguracja i&nbsp;możemy pominąć proces analizy biznesowej. 
 </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
        A czym jest formalizacja analizy przedwdrożeniowej?
 
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
    Analiza przybiera formę dokumentu. Jest ona bowiem efektem serii spotkań osobistych lub sesji zdalnych, podczas których szczegółowo opisujemy wszystkie oczekiwania klienta. 
        </p>
        
        <p style={{
          paddingTop: 15,
        }}><strong> 
        Następnie powstaje pytanie jak przygotować się do analizy?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
        Klient i dostawca powinni gruntownie przygotować się do procesu analizy przedwdrożeniowej. Większa odpowiedzialność spoczywa na dostawcy, który zobowiązany jest do udzielania kontrahentowi wskazówek ułatwiających konieczne działania. 
</p>
<p>Wsparcie dostawcy powinno wyglądać w&nbsp;następujący sposób:
    
       <ul><li>Przed spotkaniem dostawca podsumowuje wszystkie ustalenia z etapu rozmów handlowych i prezentacji systemu.</li>
       <li>Następnie dochodzi do przygotowania listy pytań i kwestii, które klient po swojej stronie powinien przemyśleć.</li>
       <li>W toku prac obie strony powinny umawiać się na odrabianie tzw. “zadań domowych”, czyli obowiązków, które wymagają realizacji wewnętrznej i szerszych ustaleń oraz spotkań. Warto zaznaczyć, że ciężko będzie od razu zdefiniować te zadania.</li>
       </ul>
       </p>





      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz skutecznie wdrożyć system w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami – przygotujemy dla Ciebie ofertę!

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Przejdź do kontaktu
            </Link>
          </div>
          </div>
    </section>











                <p style={{
          paddingTop: 15,
        }}><strong> 
     Z jakich etapów składa się analiza?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
        W głównej mierze zależy to od dostawcy. Natomiast nasz sposób prowadzenia analizy przedwdrożeniowej bazuje na wieloletnim doświadczeniu i sprawdzonej praktyce. Stworzyliśmy pewien szablon, który gwarantuje, że&nbsp;nasza analiza kończy się sukcesem.
</p>
<p>Wymieniając najważniejsze etapy analizy przedwdrożeniowej skupilibyśmy się na następujących elementach: </p>

<p> 
<ul> 
<li>Po pierwsze <strong>definiujemy wspólnie z klientem tło biznesowe</strong>, czyli formułujemy krótki opis firmy. Pozwala to analitykowi zapoznać się ze specyfiką danego przedsiębiorstwa, a dostawcy lepiej dostosować system do&nbsp;indywidualnych potrzeb kontrahenta.</li>
<li>Następnie <strong>ustalamy i opisujemy interesariuszy danego projektu</strong>, czyli mówimy komu ta inicjatywa przyniesie korzyści i jakie one będą. </li>
<li>Później <strong>skupiamy się na zdefiniowaniu aktorów</strong>, czyli już konkretnych ról, które mają występować w&nbsp;systemie i pomogą nam skonfigurować uprawnienia do poszczególnych modułów.</li>
<li>Następnym krokiem jest <strong>określenie celów projektu, wyzwań, czy aktualnych problemów biznesowych</strong>, które trapią klienta. Dzięki temu, łatwiej nam rozwiać wszelkie wątpliwości dotyczące wdrożenia.</li>
<li>Na podstawie powyższego, <strong>definiujemy produkty projektu</strong>, czyli mniejsze etapy. Staramy się również uszeregować je względnie z priorytetami, żeby zacząć od najważniejszych rzeczy, a skończyć na kwestiach “nice to have”.</li>
<li>Na samym końcu, po opisaniu całego zakresu <strong>powstaje budżet wdrożenia oraz harmonogram</strong>, dzięki któremu klient ma świadomość kiedy może spodziewać się poszczególnych etapów oraz całego systemu do&nbsp;eksploatacji.</li>
</ul>
</p>

                        <p style={{
          paddingTop: 15,
        }}><strong> 
        Który etap Twoim zdaniem jest najtrudniejszy? 
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
     Wszystko to zależy od indywidualnej analizy. Dlatego upraszczając odpowiedź – ma na to wpływ sytuacja klienta i jego konkretne potrzeby. 

</p>

                                <p style={{
          paddingTop: 15,
        }}><strong> 
        A co następuje po analizie?

        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
     Następnie po etapie analizy wdrażamy działania i&nbsp;dajemy klientowi możliwość podjęcia decyzji, czy&nbsp;projekt:
        </p>
        <p>
        <ul>
        <li>jest realizowany;</li>
        <li>znajduje uzasadnienie biznesowe;</li>
        <li>czy poprzestajemy na etapie samej analizy.</li>
        </ul>
        </p>
        <p>Najczęściej jednak rozpoczynamy wdrożenie systemu zgodnie z planem, który określiliśmy w&nbsp;dokumencie początkowym. Istotne jest to, że&nbsp;cały zakres dzielimy na etapy i podążając za nimi wykonujemy poszczególne prace. 
        </p>

        <p style={{
          paddingTop: 15,
        }}><strong> 
        Co w przypadku nowych problemów?
         </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
       Rzeczywistość biznesowa ma to do siebie, że lubi się dynamicznie zmieniać. Dlatego też projekt dzielimy na mniejsze etapy, by móc szybciej reagować na potencjalne zmiany. 
</p>
<p>Przykładowo, jeżeli po fazie pierwszej klient pragnie rozbudować system, to dajemy mu taką możliwość. Jeśli&nbsp;chciałby również zmienić priorytety poszczególnych etapów, czy też nawet zrezygnować z jakiegoś działania, to jest to oczywiście możliwe. 
        </p>
<p>Zależy nam na najlepszej funkcjonalności i&nbsp;zadowoleniu klienta. Dlatego na bieżąco reagujemy na rzeczywistość, która otacza naszych kontrahentów oraz bierzemy pod uwagę ich bieżące potrzeby. Jeżeli&nbsp;trzeba wprowadzić zmiany w tym zakresie, to je po prostu wdrażamy.</p>
    
         <p style={{
          paddingTop: 15,
        }}><strong> 
       Jakie jest znaczenie możliwości korekcji obranego kursu?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
    Istotne jest to, że korelacja obranego kursu jest możliwa, ponieważ nie zostawiamy klienta z&nbsp;wcześniejszymi ustaleniami, tylko dopasowujemy się do jego potrzeb. Szczególnie, że zmiany dotyczące zakresu nie muszą zawsze wiązać się z&nbsp;dynamicznym zwrotem  w organizacji, zmianą kursu, czy też wizją wdrożenia. Może zaistnieć sytuacja, w której to z różnych względów pewien element został pominięty na etapie analizy i&nbsp;później stwierdzono, że warto ten punkt uzupełnić.
        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
      Co powinno nastąpić po wdrożeniu?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
    Jeżeli etap wdrożenia przebiegł pomyślnie to klient ma apetyt na więcej. W takiej sytuacji rozbudowujemy istniejące moduły i czasem rozmawiamy też o wykorzystaniu rozwiązań informatycznych w nowych obszarach.
</p>
<p> Przykładowo – istnieją sytuacje, w których po wdrożeniu modułu CRM, dochodzi do rozmów o&nbsp;usprawnieniach np. w obszarze serwisowym. Jest to pewnego rodzaju rozwój systemu i&nbsp;wykorzystanie go w szerszym zakresie.
Sama&nbsp;analiza przedwdrożeniowa pozwoli: stworzyć wizję, następnie uszczegółowić ją oraz końcowo uporządkować i&nbsp;poznać się lepiej z dostawcą.
</p>

        <p style={{
          paddingTop: 15,
        }}><strong> 
      Podsumowanie
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
   Warto podkreślić, że analiza przedwdrożeniowa jest fundamentem współpracy. Ma istotny wpływ na sprawność przebiegu procesu wdrożenia rozwiązania informatycznego, ułatwia późniejsze użytkowanie oraz&nbsp;oddziałuje na efektywność pracy w tym obszarze. Jest to również ważne zagadnienie, gdyż świat biznesu nieustannie się zmienia, a&nbsp;naszym celem jest dostosowanie się do potrzeb klientów i spełnienie ich indywidualnych oczekiwań.
</p>


      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz skutecznie wdrożyć system w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami – przygotujemy dla Ciebie ofertę!

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Przejdź do kontaktu
            </Link>
          </div>
          </div>
    </section>

      <p>
        <strong>Zachęcamy do obejrzenia również:</strong>
        <ul>
          <li>
            <Link to="/co-to-jest-crm/">Co to jest CRM? Wywiad z ekspertem</Link>
          </li>
          <li>
            <Link to="/wdrozenie-crm/">
              {" "}
              Porażki wdrożeń CRM: dlaczego się nie udaje?
            </Link>
          </li>
         
        </ul>
      </p>
    </Artykul>
  );
};

export default Analiza;
